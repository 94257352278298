var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list.length
    ? _c(
        "ul",
        { staticClass: "me-search__list" },
        [
          _vm._l(_vm.list, function(item, index) {
            return _c("li", { key: index, staticClass: "item" }, [
              _vm.type === "personalVos"
                ? _c(
                    "div",
                    { staticClass: "personalVos" },
                    [
                      _c("Avatar", {
                        attrs: {
                          src: item.profilePhoto,
                          width: "50px",
                          height: "50px",
                          id: item.providerId
                        },
                        on: {
                          click: function($event) {
                            return _vm.handlerToDetail(item.providerId, "user")
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "personalVosInfo" },
                        [
                          _c("div", {
                            staticClass: "personaName",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.keyword
                                  ? _vm.lightHeight(item.name, _vm.keyword)
                                  : item.name
                              )
                            }
                          }),
                          _c("Action", {
                            staticClass: "item__info--action",
                            attrs: {
                              actionData: {
                                likeState: item.likeStatus,
                                zanNum: item.likeCount,
                                type: "user"
                              },
                              showChat: item.type === "user" ? true : false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "groupVos"
                ? _c(
                    "div",
                    {
                      staticClass: "groupVos",
                      on: {
                        click: function($event) {
                          return _vm.handlerToDetail(item.groupId, "group")
                        }
                      }
                    },
                    [
                      _c("Thumb", {
                        staticClass: "thumb",
                        attrs: {
                          src: item.groupHeader,
                          width: "100px",
                          height: "80px"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "groupInfo" },
                        [
                          _c("div", { staticClass: "groupName" }, [
                            _vm._v(_vm._s(item.groupName))
                          ]),
                          _c("Action", {
                            staticClass: "item__info--action",
                            attrs: {
                              actionData: {
                                likeState: item.likeStatus,
                                zanNum: item.likeCount,
                                type: "group"
                              },
                              showChat: item.type === "user" ? true : false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "helogigVos"
                ? _c(
                    "div",
                    {
                      staticClass: "helogigVos",
                      on: {
                        click: function($event) {
                          return _vm.handlerToDetail(item.gigId, "service")
                        }
                      }
                    },
                    [
                      _c("Thumb", {
                        attrs: {
                          src:
                            item.attachments.length &&
                            item.attachments[0].type === 3
                              ? item.attachments[0].path
                              : item.profilePhoto
                        }
                      }),
                      _c("div", { staticClass: "helogigVosInfo" }, [
                        _c("div", { staticClass: "helogigVosTitle" }, [
                          _vm._v(
                            "\n          " + _vm._s(item.title) + "\n        "
                          )
                        ]),
                        _c("div", { staticClass: "helogigVosContent" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("parseFlilter")(item.detail)) +
                              "\n        "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "group"
                ? _c(
                    "div",
                    {
                      staticClass: "groupVos",
                      on: {
                        click: function($event) {
                          return _vm.handlerToDetail(item.id, "group")
                        }
                      }
                    },
                    [
                      _c("Thumb", {
                        staticClass: "thumb",
                        attrs: {
                          src: item.avatar,
                          width: "100px",
                          height: "80px"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "groupInfo" },
                        [
                          _c("div", { staticClass: "groupName" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("Action", {
                            staticClass: "item__info--action",
                            attrs: {
                              actionData: {
                                likeState: item.actionData.likeState,
                                zanNum: item.actionData.zanNum,
                                type: "group"
                              },
                              showChat: item.type === "user" ? true : false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "user"
                ? _c(
                    "div",
                    {
                      staticClass: "groupVos",
                      on: {
                        click: function($event) {
                          return _vm.handlerToDetail(item.id, "user")
                        }
                      }
                    },
                    [
                      _c("Thumb", {
                        staticClass: "thumb",
                        attrs: {
                          src: item.avatar,
                          width: "100px",
                          height: "80px"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "groupInfo" },
                        [
                          _c("div", { staticClass: "groupName" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("Action", {
                            staticClass: "item__info--action",
                            attrs: {
                              actionData: {
                                likeState: item.actionData.likeState,
                                zanNum: item.actionData.zanNum,
                                type: "group"
                              },
                              showChat: item.type === "user" ? true : false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          }),
          _vm.count && _vm.count > _vm.list.length
            ? _c("li", { staticClass: "item more" }, [
                _c("i", {
                  staticClass: "me-icon-more-dian more",
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "list",
                        params: { type: _vm.type }
                      })
                    }
                  }
                })
              ])
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }