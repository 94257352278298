<template>
  <ul class="me-search__list" v-if="list.length">
    <li v-for="(item, index) in list" :key="index" class="item">
      <!-- 人 -->
      <div v-if="type === 'personalVos'" class="personalVos">
        <Avatar :src="item.profilePhoto" width="50px" height="50px" :id="item.providerId"
          @click="handlerToDetail(item.providerId, 'user')" />
        <div class="personalVosInfo">
          <div class="personaName" v-html="keyword ? lightHeight(item.name, keyword) : item.name"></div>
          <Action class="item__info--action"
            :actionData="{ 'likeState': item.likeStatus, 'zanNum': item.likeCount, 'type': 'user' }"
            :showChat="item.type === 'user' ? true : false" />
        </div>
      </div>
      <!-- 群 -->
      <div v-if="type == 'groupVos'" class="groupVos" @click="handlerToDetail(item.groupId, 'group')">
        <Thumb :src="item.groupHeader" width="100px" height="80px" class="thumb" />
        <div class="groupInfo">
          <div class="groupName">{{ item.groupName }}</div>
          <Action class="item__info--action"
            :actionData="{ 'likeState': item.likeStatus, 'zanNum': item.likeCount, 'type': 'group' }"
            :showChat="item.type === 'user' ? true : false" />
        </div>
      </div>
      <!-- 帖子 -->
      <div v-if="type == 'helogigVos'" class="helogigVos" @click="handlerToDetail(item.gigId, 'service')">
        <Thumb
          :src="item.attachments.length && item.attachments[0].type === 3 ? item.attachments[0].path : item.profilePhoto" />
        <div class="helogigVosInfo">
          <div class="helogigVosTitle">
            {{ item.title }}
          </div>
          <div class="helogigVosContent">
            {{ item.detail | parseFlilter }}
          </div>
        </div>
      </div>

      <!-- 从首页推荐的群点更多进来的 -->
      <div v-if="type == 'group'" class="groupVos" @click="handlerToDetail(item.id, 'group')">
        <Thumb :src="item.avatar" width="100px" height="80px" class="thumb" />
        <div class="groupInfo">
          <div class="groupName">{{ item.name }}</div>
          <Action class="item__info--action"
            :actionData="{ 'likeState': item.actionData.likeState, 'zanNum': item.actionData.zanNum, 'type': 'group' }"
            :showChat="item.type === 'user' ? true : false" />
        </div>
      </div>

      <!-- 从首页推荐的用户更多进来的 -->
      <div v-if="type == 'user'" class="groupVos" @click="handlerToDetail(item.id, 'user')">
        <Thumb :src="item.avatar" width="100px" height="80px" class="thumb" />
        <div class="groupInfo">
          <div class="groupName">{{ item.name }}</div>
          <Action class="item__info--action"
            :actionData="{ 'likeState': item.actionData.likeState, 'zanNum': item.actionData.zanNum, 'type': 'group' }"
            :showChat="item.type === 'user' ? true : false" />
        </div>
      </div>



      <!-- <div class="item__info">
        <span
          class="item__info--title"
          @click="handlerToDetail(item.id)"
          v-html=" keyword ? lightHeight(item.name, keyword) : item.name"
        ></span>
        <div style="margin-bottom:10px"  v-html="item.actionData.desc"></div>
        <div class="item__info--tags">
          <van-tag
            plain
            round
            type="success"
            v-for="(tag, index) in formartTags(item.skills)"
            :key="index"
          >
            <i v-html="keyword ? lightHeight(tag,keyword):tag "></i>
          </van-tag>
        </div>
        <Action
          class="item__info--action"
          :actionData="{...item.actionData,type }"
          :showChat="item.type === 'user' ? true : false"
        />
      </div> -->
    </li>
    <li v-if="count && count > list.length" class="item more">
      <i class="me-icon-more-dian more" @click="$router.push({ name: 'list', params: { type } })"></i>
    </li>
  </ul>
</template>
<script>
import Avatar from "@/components/common/avatar";
import Thumb from "@/components/common/thumb";
import { formartTags, lightHeight } from "@/utils/help";
import Action from "@/components/business/h5/actions";
export default {
  name: "list-temp",
  data() {
    return {
      keyword: ""
    };
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 0
    }
  },
  components: {
    Avatar,
    Thumb,
    Action
  },
  watch: {
    "$store.state.keyword": {
      handler(newly) {
        this.keyword = newly;
      },
      deep: true
    }
  },
  created() {
    this.keyword = this.$store.state.keyword;
  },
  filters: {
    parseFlilter(s) {
      try {
        return JSON.parse(s)[0].content;
      } catch (error) {
        return s == '[]' ? '' : s
      }
    }
  },
  methods: {
    formartTags,
    lightHeight,
    handlerToDetail(id, type) {
      console.log(type)
      id = "pai_" + Base64.encode(id)
      this.$router.push({
        name:
          type === "user"
            ? "user"
            : type === "group"
              ? "group"
              : "detail",
        params: { type, id }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-search__list) {
  margin: 0;
  border-bottom: 10px solid #eeeeee;

  &:last-child {
    border: 0;
  }

  +b(item) {
    display: flex;
    flex-flow: row;
    padding: 0.9rem;
    border-bottom: 1px solid #f5f5f5;

    &.more {
      font-size: 26px;
      color: #999999;
      text-align: center;
      display: flex;
      flex-flow: row;
      justify-content: center;
      padding: 0.3rem 0;
    }

    +e(info) {
      display: flex;
      flex-flow: column;
      text-align: left;
      position: relative;
      width: 100%;

      +m(title) {
        font-size: 15px;
        margin: 0 0 0.5rem 0;
      }

      +m(tags) {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        span {
          margin: 0 0.25rem 0.25rem 0;
        }
      }

      +m(action) {
        margin: 0.5rem 0 0;
        float: right;
      }
    }
  }
}

.groupVos{
  display: flex;
  align-items: center 
  width 100%;
  .thumb{
    margin-right: 10px;
    display: flex;

  }
  .groupInfo{
    width: 100%
  }
}
.personalVos{
  display: flex;
  align-items: center 
  width 100%;
  .personalVosInfo{
    width: 100%
  }
}

.helogigVos{
  display: flex;
  // align-items: center 
  width 100%;
  .helogigVosInfo{
    margin-left: 10px
    flex: 1;
    overflow hidden
  }
}
</style>